import './construction.scss';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Translation from '../../components/translation/Translation';
import meditatingGirl from '../../imgs/pages/construction/meditatingGirl.jpg';
import lazyMeditatingGirl from '../../imgs/pages/construction/meditatingGirl-min.jpg';

const Construction = ({ language }) => {
	const bundle = 'construction';

	return (
		<div className="construction-page">
			<div className="construction-main-container">
				<h2 className="construction-title"><Translation bundle={bundle} name="warning_construction" language={language}/></h2>
				<LazyLoadImage 
					className="construction-image animate__animated animate__pulse" 
					src={meditatingGirl}
					placeholdersrc={lazyMeditatingGirl}
					effect="blur"
				/>
				<p  className="construction-text" ><Translation bundle={bundle} name="warning_meditation" language={language}/></p>
				<p  className="construction-text"><Translation bundle={bundle} name="warning_apologies" language={language}/></p>
			</div>
		</div>
	);
};

export default Construction;