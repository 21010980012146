import { useState } from 'react';
import { BrowserRouter, Route,Routes } from 'react-router-dom';

import Footer from './components/footer/Footer';
import Nav from './components/nav/Nav';
import Construction from './pages/construction/Construction';
import Home from './pages/home/Home';
import Meditations from './pages/meditations/Meditations';

function App() {
	const [ language, setLanguage ] = useState('es_ES');
	
	return (
		<BrowserRouter>
			<Nav language={language} setLanguage={setLanguage}/>
			<Routes>
				<Route exact path="/" element={<Home language={language}/>} />
				<Route exact path="/meditations" element={<Meditations language={language}/>} />
				<Route exact path="/who" element={<Construction language={language}/>} />
				<Route exact path="/classes" element={<Construction language={language}/>} />
				<Route exact path="/workshops" element={<Construction language={language}/>} />
				{/* <Route path="/*" element={} /> */}
			</Routes>
			<Footer language={language}/>
		</BrowserRouter>
	);
}

export default App;
