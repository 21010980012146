import './header.scss';
import 'animate.css';

import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import hands from '../../imgs/components/header/MANOS.png';
import lazyHands from '../../imgs/components/header/MANOS-min.png';
import Translation from '../translation/Translation';

const Header = ({ language }) => {
	const [ isClassRemoved, setIsClassRemoved ] = useState(false);
	const bundle = 'header';

	useEffect(() => {
		setTimeout(() => {
			setIsClassRemoved(true);
		}, 2000);
	}, []);
	
	return (
		<header className="header">
			<div className="header-content-container">
				<h1 className="header-title">
					<Translation bundle={bundle} name="title" language={language}/>
				</h1>
				<p className="header-text">
					<Translation bundle={bundle} name="subtitle" language={language}/>
				</p>

				<div className="header-button-container">
					<a 
						href="https://wa.me/34685808092"
						target="_blank"
						rel="noreferrer"
						className={`default-button red-button ${!isClassRemoved ? 'animate__animated animate__zoomInLeft' : ''}`
						}>
						<Translation bundle={bundle} name="first_button" language={language}/>
					</a>
				</div>
			</div>
			<div className="header-background-image-container animate__animated animate__fadeInRight">
				<LazyLoadImage 
					height="100%"
					loading="lazy"
					className="header-background-image"
					src={hands} 
					placeholdersrc={lazyHands}
					effect="blur"
					alt="Manos abiertas"/>
			</div>
			
		</header>);
};


export default Header;