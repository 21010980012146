import './home.scss';
import 'animate.css';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Header from '../../components/header/Header';
import Translation from '../../components/translation/Translation';
import { girlMeditating, lazyGirlMeditating } from '../../imgs/pages';
import {
	colorCircle, ganesha, 	girlPrayer, hamsa, hand,lazyColorCircle, lazyGanesha, lazyGirlPrayer, lazyHamsa, lazyHand, lazyLoto, lazyRedheadBoy, lazyYinyan,
	loto, redheadBoy, yinyan } from '../../imgs/pages/home';
import { translateText } from '../../utils/helper_functions';

const location = 'home';
const BUNDLE = {
	recommendations: `${location}/recommendations`,
	cards: `${location}/cards`,
	boy: `${location}/boy`, 
	girl: `${location}/girl`,
	final: `${location}/final`
};

const Home = ({ language }) => {
	return (
		<>
			<Header language={language}/>
			<main>
				<section className="recommendations-section divisor-section">
					<p className="recommendations-text white-text recommendations-description">
						<span><Translation bundle={BUNDLE.recommendations} name="first_description_1" language={language}/></span>
						<span><Translation bundle={BUNDLE.recommendations} name="first_description_2" language={language}/></span>
					</p>
					<p className="recommendations-text purple-text">
						<Translation bundle={BUNDLE.recommendations} name="second_description" language={language}/>
					</p>
				</section>
				<section className="cards-section">
					<article className="card-container first-card-top-border">
						<div className="card-image-container">
							<LazyLoadImage 
								placeholdersrc={lazyGirlMeditating}
								effect="blur"
								className="card-image"
								src={girlMeditating} 
								alt={translateText(BUNDLE.cards, 'card_1_alt', language)}
							/>
						</div>
						<h3 className="card-title">
							<Translation bundle={BUNDLE.cards} name="card_1_title" language={language}/>
						</h3>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_1_paragraph_1" language={language}/>
						</p>
						<ul className="card-list">
							<li className="card-text"><Translation bundle={BUNDLE.cards} name="card_1_item_1" language={language}/></li>
							<li className="card-text"><Translation bundle={BUNDLE.cards} name="card_1_item_2" language={language}/></li>
							<li className="card-text"><Translation bundle={BUNDLE.cards} name="card_1_item_3" language={language}/></li>
						</ul>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_1_paragraph_2" language={language}/>
						</p>
						<a 
							href="https://wa.me/34685808092"
							target="_blank"
							rel="noreferrer"
							className="default-button card-button first-card-button-color">
							<Translation bundle={BUNDLE.cards} name="card_1_button" language={language}/>
						</a>
					</article>
					<article className="card-container second-card-top-border">
						<div className="card-image-container">
							<LazyLoadImage 
								placeholdersrc={lazyHand}
								effect="blur"
								className="card-image"
								src={hand} 
								alt={translateText(BUNDLE.cards, 'card_2_alt', language)}
							/>
						</div>
						<h3 className="card-title">
							<Translation bundle={BUNDLE.cards} name="card_2_title" language={language}/>
						</h3>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_2_paragraph_1" language={language}/>
						</p>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_2_paragraph_2" language={language}/>
						</p>
						<a
							href="https://wa.me/34685808092"
							target="_blank"
							rel="noreferrer" 
							className="default-button card-button second-card-button-color">
							<Translation bundle={BUNDLE.cards} name="card_2_button" language={language}/>
						</a>
					</article>
					<article className="card-container third-card-top-border">
						<div className="card-image-container">
							<LazyLoadImage 
								placeholdersrc={lazyColorCircle}
								effect="blur"
								className="card-image"
								src={colorCircle} 
								alt={translateText(BUNDLE.cards, 'card_3_alt', language)}
							/>
						</div>
						<h3 className="card-title">
							<Translation bundle={BUNDLE.cards} name="card_3_title" language={language}/>
						</h3>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_3_paragraph_1" language={language}/>
						</p>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_3_paragraph_2" language={language}/>
						</p>
						<p className="card-text">
							<Translation bundle={BUNDLE.cards} name="card_3_paragraph_3" language={language}/>
						</p>
						<a 
							href="https://wa.me/34685808092"
							target="_blank"
							rel="noreferrer"
							className="default-button card-button third-card-button-color">
							<Translation bundle={BUNDLE.cards} name="card_3_button" language={language}/>
						</a>
					</article>
				</section>
				<section className="boy-section-container">
					<div className="boy-hindu-descriptions-container divisor-section">
						<div className="boy-hindu-description">
							<LazyLoadImage 
								placeholdersrc={lazyHamsa}
								effect="blur"
								className="boy-hindu-image" alt={translateText(BUNDLE.boy, 'hamsa_alt', language)} src={hamsa}/>
							<p><Translation bundle={BUNDLE.boy} name="hamsa" language={language}/></p>
						</div>
						<div className="boy-hindu-description">
							<LazyLoadImage
								placeholdersrc={lazyGanesha}
								effect="blur"
								className="boy-hindu-image" alt={translateText(BUNDLE.boy, 'ganesha_alt', language)} src={ganesha}/>
							<p><Translation bundle={BUNDLE.boy} name="ganesha" language={language}/></p>
						</div>
						<div className="boy-hindu-description">
							<LazyLoadImage 
								placeholdersrc={lazyLoto}
								effect="blur"
								className="boy-hindu-image" alt={translateText(BUNDLE.boy, 'loto_alt', language)} src={loto}/>
							<p><Translation bundle={BUNDLE.boy} name="loto" language={language}/></p>
						</div>
						<div className="boy-hindu-description">
							<LazyLoadImage 
								placeholdersrc={lazyYinyan}
								effect="blur"
								className="boy-hindu-image" alt={translateText(BUNDLE.boy, 'yinyan_alt', language)} src={yinyan}/>
							<p><Translation bundle={BUNDLE.boy} name="yinyan" language={language}/></p>
						</div>
					</div>
					<div className="right-text-container">
						<div className="right-text-title-container">
							<h2 className="right-title">
								<span><Translation bundle={BUNDLE.boy} name="title" language={language}/></span>
								<span><Translation bundle={BUNDLE.boy} name="title_2" language={language}/></span>
							</h2>
							<p className="right-first-subtitle"><Translation bundle={BUNDLE.boy} name="first_subtitle" language={language}/></p>
							<p className="purple-text right-second-subtitle"><Translation bundle={BUNDLE.boy} name="second_subtitle" language={language}/></p>
							<AnimationOnScroll className="animate__fadeInRightBig" animateIn="animate__fadeInRightBig" >
								<a
									href="https://wa.me/34685808092"
									target="_blank"
									rel="noreferrer"
									className="default-button red-button right-button"><Translation bundle={BUNDLE.boy} name="button" language={language}/></a>
							</AnimationOnScroll>
						</div>
					</div>
					<AnimationOnScroll className="background-image-container boy-background-image-container animate__fadeInLeft" animateIn="animate__fadeInLeftBig">
						<LazyLoadImage 
							height="100%"
							placeholdersrc={lazyRedheadBoy}
							effect="blur"
							className="boy-background-image"
							src={redheadBoy} 
							alt={translateText(BUNDLE.boy, 'boy_alt', language)}/>
					</AnimationOnScroll>
				</section>
				<section className="girl-section-container">
					<div className="divisor-section"></div>
					<div className="girl-text-container">
						<div className="girl-text-title-container">
							<h2 className="home-title"><Translation bundle={BUNDLE.girl} name="title" language={language}/></h2>
							<p className="home-first-subtitle"><Translation bundle={BUNDLE.girl} name="first_subtitle" language={language}/></p>
							<p className="purple-text home-second-subtitle"><Translation bundle={BUNDLE.girl} name="second_subtitle" language={language}/></p>
							<AnimationOnScroll  animateIn="animate__zoomInLeft" >
								<a 
									href="https://wa.me/34685808092"
									target="_blank"
									rel="noreferrer"
									className="default-button red-button home-button"><Translation bundle={BUNDLE.girl} name="button" language={language}/></a>
							</AnimationOnScroll>
						</div>
					</div>
					<AnimationOnScroll className="girl-background-image-container animate__fadeInRight" animateIn="animate__fadeInRight">
						<LazyLoadImage 
							height="100%"
							placeholdersrc={lazyGirlPrayer}
							effect="blur"
							className="girl-background-image"
							src={girlPrayer} 
							alt={translateText(BUNDLE.girl, 'boy_alt', language)}/>
					</AnimationOnScroll>
				</section>
				<section className="final-section">
					<div className="divisor-section"></div>
					<div className="final-text-container">
						<h2 className="final-title">&quot;<Translation bundle={BUNDLE.final} name="title" language={language}/>&quot; - GMCKS</h2>
						<p className="final-subtitle"><Translation bundle={BUNDLE.final} name="first_subtitle" language={language}/></p>
						<p className="final-subtitle"><Translation bundle={BUNDLE.final} name="second_subtitle" language={language}/></p>
						<AnimationOnScroll  animateIn="animate__pulse" >
							<a
								href="https://wa.me/34685808092"
								target="_blank"
								rel="noreferrer"
								className="default-button blue-button final-button"><Translation bundle={BUNDLE.final} name="button" language={language}/></a>
						</AnimationOnScroll>
					</div>
				</section>
			</main>
		</>
	);
};

export default Home;