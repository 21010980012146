import React from 'react';

import { translateText } from '../../utils/helper_functions';

const Translation = ({ bundle, name, params, language }) => {
	const translation = translateText(bundle, name, language, params);
	return (
		<>
			{translation}
		</>
	);
};

export default Translation;