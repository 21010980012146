
const LANGUAGE_SELECTOR = {
	es_ES: 'es_ES',
	ca_CA: 'ca_CA'
};
const LANGUAGES = {
	[LANGUAGE_SELECTOR.es_ES]: 'ES',
	[LANGUAGE_SELECTOR.ca_CA]: 'CA'
};

const getSelectedLanguage = (selectedLanguage, setLanguage, setLanguageMenuOpened, languageMenuOpened) => {
	setLanguage(selectedLanguage);
	languageMenuOpened && setLanguageMenuOpened(false);
};

export {
	getSelectedLanguage,
	LANGUAGE_SELECTOR,
	LANGUAGES };