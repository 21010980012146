import './footer.scss';

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
	click, instagram, lazyClick, lazyInstagram, lazyLocation, lazyLogo, lazyMail, lazyPhone, lazyPinterest, lazyYouTube,
	location, logo, mail, phone, pinterest, youTube } from '../../imgs/components/footer';
import { translateText } from '../../utils/helper_functions';
import Translation from '../translation/Translation';

const Footer = ({ language }) => {
	const footer_bundle = 'footer';
	const date = new Date();
	const year = date.getFullYear();

	return (
		<footer>
			<div className="footer-container">
				<ul className="footer-title-container">
					<li><Translation bundle={footer_bundle} name="contact" language={language}/></li>
					<li><Translation bundle={footer_bundle} name="bank_details" language={language}/></li>
					<li><Translation bundle={footer_bundle} name="social_network" language={language}/></li>
				</ul>
				<div className="footer-info-container">
					<ul className="footer-contact-container">
						<li className="footer-responsive-title-container">
							<Translation bundle={footer_bundle} name="contact" language={language}/>
						</li>
						<li>
							<LazyLoadImage 
								placeholdersrc={lazyClick}
								effect="blur"
								className="footer-icon" src={click}/>
							<p className="footer-location-text"><Translation bundle={footer_bundle} name="city" language={language}/></p>
						</li>
						<li>
							<a href="https://goo.gl/maps/Gv5TdEdMonXRLDBD6" target="_blank" rel="noreferrer">
								<LazyLoadImage 
									placeholdersrc={lazyLocation}
									effect="blur"
									className="footer-icon" src={location}/>
								<p className="footer-contact-text"><Translation bundle={footer_bundle} name="address" language={language}/></p>
							</a>
						</li>
						<li>
							<a  href="https://wa.me/34640105503" target="_blank" rel="noreferrer">
								<LazyLoadImage 
									placeholdersrc={lazyPhone}
									effect="blur"
									className="footer-icon" src={phone}/>
								<p className="footer-contact-text"><Translation bundle={footer_bundle} name="phone" language={language}/></p>
							</a>
						</li>
						<li>
							<a href="mailto:info@pranadorado.com" target="_blank" rel="noreferrer">
								<LazyLoadImage 
									placeholdersrc={lazyMail}
									effect="blur"
									className="footer-icon" src={mail}/>
								<p className="footer-contact-text"><Translation bundle={footer_bundle} name="mail" language={language}/></p>
							</a>
						</li>
					</ul>
					<ul className="footer-bank-container">
						<li className="footer-responsive-title-container">
							<Translation bundle={footer_bundle} name="bank_details" language={language}/>
						</li>
						<li className="footer-bank-item"><Translation bundle={footer_bundle} name="bank_name" language={language}/></li>
						<li className="footer-bank-item"><Translation bundle={footer_bundle} name="bank_number" language={language}/></li>
						<li className="footer-bank-item"><Translation bundle={footer_bundle} name="swift" language={language}/></li>
					</ul>
					<ul className="footer-network-container">
						<li className="footer-responsive-title-container">
							<Translation bundle={footer_bundle} name="social_network" language={language}/>
						</li>
						<div className="footer-network-icons-container">
							<li><a><LazyLoadImage 
								placeholdersrc={lazyPinterest}
								effect="blur"className="footer-pinterest-icon" src={pinterest} /></a></li>
							<li><a href='https://www.instagram.com/prana_dorado/' target="_blank" rel="noreferrer"><LazyLoadImage 
								placeholdersrc={lazyInstagram}
								effect="blur"
								className="footer-instagram-icon" src={instagram} /></a></li>
							<li><a><LazyLoadImage 
								placeholdersrc={lazyYouTube}
								effect="blur"
								className="footer-youtube-icon" src={youTube} /></a></li>
						</div>
					</ul>
				</div>
				<div className="footer-logo-container">
					<LazyLoadImage 
						placeholdersrc={lazyLogo}
						effect="blur"
						alt="Prana Dorado Logo" className="footer-logo" src={logo} />
				</div>
			</div>
			<div className="footer-copyright">
				<p>
					<span>Copyright © by Prana Dorado {year}</span>
					<span>-</span>
					<span>&quot;{translateText(footer_bundle, 'phrase', language)}&quot; - MCKS</span>
				</p>
			</div>
		</footer>
	);
};

export default Footer;