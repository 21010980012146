import './meditations.scss';
import 'animate.css';

import { useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Translation from '../../components/translation/Translation';
import {
	girlMeditating, goldenArrow, goldenChevron, lazyGirlMeditating, lazyGoldenArrow, lazyGoldenChevron } from '../../imgs/pages';
import { translateText } from '../../utils/helper_functions';

const location = 'meditations';
const BUNDLE = {
	meditation: `${location}/meditation`,
	cards: `${location}/cards`,
	boy: 'home/boy', 
	girl: `${location}/girl`,
	final: `${location}/final`
};

const defaultFaqs = [
	{ id: 1, isOpened: false },
	{ id: 2, isOpened: false },
	{ id: 3, isOpened: false },
	{ id: 4, isOpened: false },
];
const Meditations = ({ language }) => {
	const [ answersOpened, setAnswersOpened ] = useState(defaultFaqs);

	const processAnswer = (faqId, answersOpened) => {
		const newAnswers = answersOpened.map(faq => {
			if(faq.id === faqId) {
				return { ...faq, isOpened: !faq.isOpened };
			}
			return faq;
		});

		setAnswersOpened(newAnswers);
	};

	return (
		<main>
			<section className="meditation-section-container">
				<h2 className="meditation-title">
					<Translation bundle={BUNDLE.meditation} name="title" language={language}/>
				</h2>
				<div className="right-text-container">
					<div className="right-text-title-container">
						<h3 className="right-title">
							<Translation bundle={BUNDLE.meditation} name="first_subtitle" language={language}/>
						</h3>
						<p className="right-first-subtitle"><Translation bundle={BUNDLE.meditation} name="first_subtitle" language={language}/></p>
						<p className="purple-text right-second-subtitle"><Translation bundle={BUNDLE.meditation} name="second_subtitle" language={language}/></p>
						<div className="right-button-container animate__animated animate__zoomInRight" >
							<a
								href="https://wa.me/34685808092"
								target="_blank"
								rel="noreferrer"
								className="default-button green-button right-button"><Translation bundle={BUNDLE.meditation} name="button" language={language}/></a>
						</div>
					</div>
				</div>
				<div className="meditation-triangle-background-container"/>
				<div className="background-image-container meditation-background-image-container animate__animated animate__fadeInLeft">
					<LazyLoadImage 
						height="100%"
						placeholdersrc={lazyGirlMeditating}
						effect="blur"
						className="boy-background-image"
						src={girlMeditating} 
						alt={translateText(BUNDLE.meditation, 'meditating_girl_alt', language)}/>
				</div>
			</section>
			<section className="meditation-power-section-container">
				<h3 className="header-title meditation-power-title">
					<Translation bundle={BUNDLE.meditation} name="power_title" language={language}/>
				</h3>
				<ul className="meditation-power-list-container">
					<li>
						<LazyLoadImage 
							height="30px"
							effect="blur"
							placeholdersrc={lazyGoldenArrow}
							className="meditation-power-list-icon"
							src={goldenArrow} 
							alt={translateText(BUNDLE.meditation, 'meditating_girl_alt', language)}/>
						<p className="meditation-power-list-text"><Translation bundle={BUNDLE.meditation} name="power_list_item_1" language={language}/></p>
					</li>
					<li>
						<LazyLoadImage 
							height="30px"
							effect="blur"
							placeholdersrc={lazyGoldenArrow}
							className="meditation-power-list-icon"
							src={goldenArrow} 
							alt={translateText(BUNDLE.meditation, 'meditating_girl_alt', language)}/>
						<p className="meditation-power-list-text" ><Translation bundle={BUNDLE.meditation} name="power_list_item_2" language={language}/></p>
					</li>
					<li>
						<LazyLoadImage 
							height="30px"
							effect="blur"
							placeholdersrc={lazyGoldenArrow}
							className="meditation-power-list-icon"
							src={goldenArrow} 
							alt={translateText(BUNDLE.meditation, 'meditating_girl_alt', language)}/>
						<p className="meditation-power-list-text"><Translation bundle={BUNDLE.meditation} name="power_list_item_3" language={language}/></p>
					</li>
				</ul>
				<h4 className="header-title meditation-faq-title">
					<Translation bundle={BUNDLE.meditation} name="power_faq" language={language}/>
				</h4>
				<div className='meditation-faq-table-container'>
					{answersOpened.map(faq => (
						<div key={faq.id} className={`meditation-faq-container ${answersOpened[faq.id - 1].isOpened ? 'open-card' : 'close-card'}`}>
							<button className='meditation-faq-button' onClick={() => processAnswer(faq.id, answersOpened)}> 
								<LazyLoadImage 
									height="30px"
									effect="blur"
									placeholdersrc={lazyGoldenChevron}
									className="meditation-power-list-icon"
									src={goldenChevron} /> 
								<p><Translation bundle={BUNDLE.meditation} name={`power_faq_${faq.id}`} language={language}/></p>
							</button>
							<div className='meditation-power-answer-container'>
								<p><Translation bundle={BUNDLE.meditation} name={`power_answer_${faq.id}`} language={language}/></p>
								<p><Translation bundle={BUNDLE.meditation} name={`power_answer_${faq.id}a`}  language={language}/></p>
							</div>
						</div>))}
					
				</div>
			</section>
			<section className="final-section">
				<div className="divisor-section"></div>
				<div className="final-text-container">
					<h2 className="final-title"><Translation bundle={BUNDLE.meditation} name="closing_title" language={language}/></h2>
					<p className="final-subtitle"><Translation bundle={BUNDLE.meditation} name="closing_subtitle" language={language}/></p>
					<AnimationOnScroll  className="animate__pulse" animateIn="animate__pulse" >
						<a
							href="https://wa.me/34685808092"
							target="_blank"
							rel="noreferrer"
							className="default-button blue-button final-button"><Translation bundle={BUNDLE.meditation} name="closing_button" language={language}/></a>
					</AnimationOnScroll>
				</div>
			</section>
		</main>
	);
};

export default Meditations;