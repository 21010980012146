import './nav.scss';

import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import catalunianFlag from '../../imgs/components/nav/cat.png';
import closeMenu from '../../imgs/components/nav/CRUZ-MOBILE-1.png';
import spanishFlag from '../../imgs/components/nav/espana.png';
import globe from '../../imgs/components/nav/globo.png';
import menuLogo from '../../imgs/components/nav/HAMBURGUESA-2.png';
import logoNav from '../../imgs/components/nav/LOGO-NAV.png';
import Translation from '../translation/Translation';
import {
	getSelectedLanguage,
	LANGUAGE_SELECTOR,
	LANGUAGES 
} from './Nav_helpers';

const Nav = ({ language, setLanguage }) => {
	const [ languageMenuOpened, setLanguageMenuOpened ] = useState(false);
	const [ isResponsiveMenuOpened, setIsResponsiveMenuOpened ] = useState(false);	
	const bundle = 'nav';
	
	return (
		<nav className="nav">
			<div className="nav-container">
				<div className="nav-logo-container">
					<NavLink to="/"> 
						<img className="nav-logo" src={logoNav} alt="Prana Dorado"/>
					</NavLink>
					
				</div>
				<ul className="nav-list nav-list-desktop">
					<li className="nav-list-item">
						<NavLink to="/who" className="nav-list-item-link">
							<Translation bundle={bundle} name="who" language={language}/>
						</NavLink>
					</li>
					<li className="nav-list-item">
						<NavLink to="/classes" className="nav-list-item-link">
							<Translation bundle={bundle} name="classes" language={language}/>
						</NavLink>
					</li>
					<li className="nav-list-item">
						<NavLink to="/workshops" className="nav-list-item-link">
							<Translation bundle={bundle} name="workshops" language={language}/>
						</NavLink>
					</li>
					<li className="nav-list-item">
						<NavLink to="/meditations" className="nav-list-item-link">
							<Translation bundle={bundle} name="meditations" language={language}/>
						</NavLink>
					</li>
					<li className="nav-list-item-language">
						<button 
							onClick={() => setLanguageMenuOpened(!languageMenuOpened)}
							className="nav-item-button nav-list-item"
						>{LANGUAGES[language]} <img className="nav-item-image" src={globe}/></button>
						<ul className={`nav-item-language-sublist ${languageMenuOpened ? 'nav-language-sublist-open' : 'nav-language-sublist-close'}`}>
							<li className="nav-sublist-item">
								<button 
									onClick={() => getSelectedLanguage(LANGUAGE_SELECTOR.es_ES, setLanguage, setLanguageMenuOpened, languageMenuOpened)}
									className="nav-item-button nav-item-button-languange"
								>
									<img className="nav-item-image" src={spanishFlag}/> 
								es - español
								</button>
							</li>
							<li className="nav-sublist-item">
								<button 
									onClick={() => getSelectedLanguage(LANGUAGE_SELECTOR.ca_CA, setLanguage, setLanguageMenuOpened, languageMenuOpened)}
									className="nav-item-button nav-item-button-languange">
									<img className="nav-item-image" src={catalunianFlag}/> 
							ca - català
								</button>
							</li>
						</ul>
					</li>
				</ul>
				<div className="nav-list-item-responsive-menu">
					<button 
						className="nav-item-button"
						onClick={() => setIsResponsiveMenuOpened(!isResponsiveMenuOpened)}
					>
						{isResponsiveMenuOpened ?
							<img 
								className="nav-item-image nav-menu-image" 
								src={closeMenu}/>
							:
							<img 
								className="nav-item-image nav-menu-image" 
								src={menuLogo}/>}
					</button>
				</div>
			</div>
			<ul 
				className={`nav-list-responsive ${isResponsiveMenuOpened ? 'nav-list-responsive-opened' : 'nav-list-responsive-closed'}`}
				onClick={() => setIsResponsiveMenuOpened(false)}
			>
				<li className="nav-list-item nav-list-item-responsive">
					<NavLink to="/who" className="nav-list-item-link-responsive">
						<Translation bundle={bundle} name="who" language={language}/>
					</NavLink>
				</li>
				<li className="nav-list-item nav-list-item-responsive">
					<NavLink 
						to="/classes"
						className="nav-list-item-link-responsive">
						<Translation bundle={bundle} name="classes" language={language}/>
					</NavLink>
				</li>
				<li className="nav-list-item nav-list-item-responsive">
					<NavLink 
						to="/workshops"
						className="nav-list-item-link-responsive"
						onClick={() => setIsResponsiveMenuOpened(false)}>
						<Translation bundle={bundle} name="workshops" language={language}/>
					</NavLink>
				</li>
				<li className="nav-list-item nav-list-item-responsive">
					<NavLink 
						to="/meditations"
						className="nav-list-item-link-responsive"
						onClick={() => setIsResponsiveMenuOpened(false)}>
						<Translation bundle={bundle} name="meditations" language={language}/>
					</NavLink>
				</li>
				<li className="nav-list-item-responsive-language nav-list-item-responsive">
					<NavLink className="nav-item-button nav-item-button-responsive nav-list-item"
					>{LANGUAGES[language]} <img className="nav-item-image" src={globe}/></NavLink>
					<ul className="nav-item-language-sublist-responsive">
						<li className="nav-sublist-item-responsive">
							<button 
								onClick={() => {
									getSelectedLanguage(LANGUAGE_SELECTOR.es_ES, setLanguage, setLanguageMenuOpened, languageMenuOpened);
									setIsResponsiveMenuOpened(false);
								}}
								className="nav-item-button nav-item-button-languange nav-item-button-languange-responsive"
							>
								<img className="nav-item-image" src={spanishFlag}/> 
								es - español
							</button>
						</li>
						<li className="nav-sublist-item-responsive">
							<button 
								onClick={() => {
									getSelectedLanguage(LANGUAGE_SELECTOR.ca_CA, setLanguage, setLanguageMenuOpened, languageMenuOpened);
									setIsResponsiveMenuOpened(false);
								}}
								className="nav-item-button nav-item-button-languange">
								<img className="nav-item-image" src={catalunianFlag}/> 
							ca - català
							</button>
						</li>
					</ul>
				</li>
			</ul>
		</nav>);
};


export default Nav;