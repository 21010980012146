import HtmlParser from 'html-react-parser';

const translateText = (bundle, name, language='es_ES', params={}) => {
	try {
		const data = require(`../l18n/bundles/${bundle}.json`);
		let value = data[name][language];
		
		Object.keys(params).forEach(param => {
			value = value.replace(`{${param}}`, params[param]);
		});
        
		return HtmlParser(value);
	} catch (error) {
		return '__unknown__';
	}
};

export { translateText };